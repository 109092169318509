import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Container,
    Box,
    Image,
    Text,
    VStack,
    Heading,
    SimpleGrid,
    Badge,
    Button,
    HStack,
    useColorModeValue,
    Skeleton,
    SkeletonText,
    useToast,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { mangaApi } from '../services/mangaApi';
import { Manga, Chapter } from '../types/manga';
import SEO from '../components/SEO';
import MangaStructuredData from '../components/StructuredData';

const MangaDetailPage: React.FC = () => {
    const { mangaId } = useParams<{ mangaId: string }>();
    const navigate = useNavigate();
    const toast = useToast();
    const [manga, setManga] = useState<Manga | null>(null);
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    useEffect(() => {
        const fetchMangaDetails = async () => {
            if (!mangaId) {
                setError('No manga ID provided');
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            setError(null);

            try {
                const [mangaData, chaptersData] = await Promise.all([
                    mangaApi.getMangaDetails(mangaId),
                    mangaApi.getMangaChapters(mangaId)
                ]);

                setManga(mangaData);
                setChapters(chaptersData.sort((a, b) => 
                    parseFloat(a.chapter) - parseFloat(b.chapter)
                ));
            } catch (error) {
                console.error('Error fetching manga details:', error);
                setError('Failed to load manga details');
                toast({
                    title: 'Error',
                    description: 'Failed to load manga details. Please try again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchMangaDetails();
    }, [mangaId, toast]);

    if (error) {
        return (
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8}>
                    <Button
                        leftIcon={<ChevronLeftIcon />}
                        onClick={() => navigate('/')}>
                        Back to Search
                    </Button>
                    <Text color="red.500">{error}</Text>
                </VStack>
            </Container>
        );
    }

    if (isLoading) {
        return (
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <Button
                        leftIcon={<ChevronLeftIcon />}
                        onClick={() => navigate('/')}
                        alignSelf="flex-start"
                    >
                        Back to Search
                    </Button>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                        <Skeleton height="400px" borderRadius="lg" />
                        <VStack align="stretch" spacing={4}>
                            <SkeletonText noOfLines={1} skeletonHeight={8} />
                            <SkeletonText noOfLines={4} spacing={4} />
                            <SimpleGrid columns={2} spacing={4}>
                                <Skeleton height="24px" />
                                <Skeleton height="24px" />
                            </SimpleGrid>
                        </VStack>
                    </SimpleGrid>
                </VStack>
            </Container>
        );
    }

    if (!manga) {
        return (
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8}>
                    <Button
                        leftIcon={<ChevronLeftIcon />}
                        onClick={() => navigate('/')}>
                        Back to Search
                    </Button>
                    <Text>Manga not found</Text>
                </VStack>
            </Container>
        );
    }

    return (
        <Container maxW="container.xl" py={8}>
            {manga && (
                <>
                    <SEO
                        title={`${manga.title} - Read on 1331 Manga`}
                        description={manga.description || `Read ${manga.title} manga online for free on 1331 Manga`}
                        image={manga.coverImage}
                    />
                    <MangaStructuredData
                        title={manga.title}
                        description={manga.description || `Read ${manga.title} manga online for free on 1331 Manga`}
                        image={manga.coverImage}
                        genre={manga.genres.join(', ')}
                        datePublished={manga.publishDate}
                        author={manga.author}
                    />
                </>
            )}
            <VStack spacing={8} align="stretch">
                <Button
                    leftIcon={<ChevronLeftIcon />}
                    onClick={() => navigate('/')}
                    alignSelf="flex-start"
                >
                    Back to Search
                </Button>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                    <Box>
                        <Image
                            src={manga.coverImage}
                            alt={manga.title}
                            borderRadius="lg"
                            width="100%"
                            maxW="500px"
                            objectFit="cover"
                            fallbackSrc="https://via.placeholder.com/500x700?text=No+Image"
                        />
                    </Box>

                    <VStack align="stretch" spacing={6}>
                        <Heading size="xl">{manga.title}</Heading>

                        <HStack spacing={2} wrap="wrap">
                            {manga.status && (
                                <Badge
                                    colorScheme={
                                        manga.status === 'ongoing'
                                            ? 'green'
                                            : manga.status === 'completed'
                                            ? 'blue'
                                            : 'gray'
                                    }
                                >
                                    {manga.status}
                                </Badge>
                            )}
                            {manga.year && (
                                <Badge colorScheme="purple">
                                    {manga.year}
                                </Badge>
                            )}
                        </HStack>

                        <Text>{manga.description}</Text>

                        {manga.tags && manga.tags.length > 0 && (
                            <Box>
                                <Text fontWeight="bold" mb={2}>
                                    Tags:
                                </Text>
                                <HStack spacing={2} wrap="wrap">
                                    {manga.tags.map((tag, index) => (
                                        <Badge
                                            key={index}
                                            colorScheme="teal"
                                            variant="subtle"
                                        >
                                            {tag}
                                        </Badge>
                                    ))}
                                </HStack>
                            </Box>
                        )}
                    </VStack>
                </SimpleGrid>

                <Box>
                    <HStack justify="space-between" align="center" mb={4}>
                        <Heading size="lg">Chapters</Heading>
                        <Text color="gray.500" fontSize="sm">
                            {chapters.length} chapters available
                        </Text>
                    </HStack>
                    <SimpleGrid
                        columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                        spacing={4}
                    >
                        {chapters
                            .slice()
                            .sort((a, b) => parseFloat(a.chapter) - parseFloat(b.chapter))
                            .map((chapter) => (
                                <Box
                                    key={chapter.id}
                                    p={4}
                                    borderRadius="lg"
                                    bg={bgColor}
                                    borderWidth="1px"
                                    borderColor={borderColor}
                                    cursor="pointer"
                                    onClick={() =>
                                        navigate(
                                            `/manga/${mangaId}/chapter/${chapter.id}`
                                        )
                                    }
                                    _hover={{
                                        transform: 'translateY(-2px)',
                                        shadow: 'md',
                                        borderColor: 'blue.500',
                                    }}
                                    transition="all 0.2s"
                                    role="group"
                                >
                                    <VStack align="stretch" spacing={1}>
                                        <HStack justify="space-between" align="center">
                                            <Text 
                                                fontWeight="bold"
                                                _groupHover={{ color: 'blue.500' }}
                                            >
                                                Chapter {chapter.chapter}
                                            </Text>
                                            {chapter.quality && (
                                                <Badge 
                                                    colorScheme="green" 
                                                    variant="subtle"
                                                    fontSize="xs"
                                                >
                                                    {chapter.quality}
                                                </Badge>
                                            )}
                                        </HStack>
                                        {chapter.title && (
                                            <Text
                                                fontSize="sm"
                                                color="gray.500"
                                                noOfLines={1}
                                                _groupHover={{ color: 'blue.400' }}
                                            >
                                                {chapter.title}
                                            </Text>
                                        )}
                                        <HStack 
                                            spacing={2} 
                                            fontSize="xs" 
                                            color="gray.500"
                                            mt={1}
                                        >
                                            {chapter.groupName && (
                                                <Text noOfLines={1}>
                                                    {chapter.groupName}
                                                </Text>
                                            )}
                                            <Text>
                                                {new Date(chapter.publishAt).toLocaleDateString()}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </Box>
                            ))}
                    </SimpleGrid>
                </Box>
            </VStack>
        </Container>
    );
};

export default MangaDetailPage;
