import React from 'react';
import {
    InputGroup,
    InputLeftElement,
    Input,
    IconButton,
    HStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon, SettingsIcon } from '@chakra-ui/icons';

interface SearchBarProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFilterClick: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange, onFilterClick }) => {
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const bgColor = useColorModeValue('white', 'gray.800');

    return (
        <HStack width="100%" spacing={4} mb={6}>
            <InputGroup size="lg">
                <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.500" />
                </InputLeftElement>
                <Input
                    placeholder="Search manga..."
                    value={value}
                    onChange={onChange}
                    bg={bgColor}
                    borderColor={borderColor}
                />
            </InputGroup>
            <IconButton
                aria-label="Filter options"
                icon={<SettingsIcon />}
                onClick={onFilterClick}
                size="lg"
            />
        </HStack>
    );
};

export default SearchBar;
