import { Manga } from '../types/manga';

const STORAGE_KEYS = {
    READING_PROGRESS: 'reading_progress',
    BOOKMARKS: 'bookmarks',
    RECENTLY_VIEWED: 'recently_viewed'
};

const MAX_RECENTLY_VIEWED = 12;

interface ReadingProgress {
    mangaId: string;
    chapterId: string;
    chapterNumber: string;
    lastReadPage: number;
    mangaTitle: string;
    mangaCover?: string;
    timestamp: number;
}

interface Bookmark {
    mangaId: string;
    chapterId: string;
    chapterNumber: string;
    pageNumber: number;
    mangaTitle: string;
    mangaCover?: string;
    note?: string;
    timestamp: number;
}

const getStorageItem = <T>(key: string): T[] => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : [];
};

const setStorageItem = <T>(key: string, value: T[]): void => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const userDataService = {
    // Reading Progress
    saveReadingProgress: (progress: Omit<ReadingProgress, 'timestamp'>) => {
        const allProgress = getStorageItem<ReadingProgress>(STORAGE_KEYS.READING_PROGRESS);
        const existingIndex = allProgress.findIndex(p => p.mangaId === progress.mangaId);

        const newProgress = {
            ...progress,
            timestamp: Date.now()
        };

        if (existingIndex !== -1) {
            allProgress[existingIndex] = newProgress;
        } else {
            allProgress.unshift(newProgress);
        }

        setStorageItem(STORAGE_KEYS.READING_PROGRESS, allProgress);
    },

    getLastReadChapter: (mangaId: string): ReadingProgress | null => {
        const allProgress = getStorageItem<ReadingProgress>(STORAGE_KEYS.READING_PROGRESS);
        return allProgress.find(p => p.mangaId === mangaId) || null;
    },

    getReadingHistory: (): ReadingProgress[] => {
        try {
            const history = localStorage.getItem(STORAGE_KEYS.READING_PROGRESS);
            return history ? JSON.parse(history) : [];
        } catch (error) {
            console.error('Error getting reading history:', error);
            return [];
        }
    },

    clearReadingHistory: (): void => {
        try {
            localStorage.removeItem(STORAGE_KEYS.READING_PROGRESS);
        } catch (error) {
            console.error('Error clearing reading history:', error);
        }
    },

    // Bookmarks
    addBookmark: (bookmark: Omit<Bookmark, 'timestamp'>) => {
        const bookmarks = getStorageItem<Bookmark>(STORAGE_KEYS.BOOKMARKS);
        const newBookmark = {
            ...bookmark,
            timestamp: Date.now()
        };

        const existingIndex = bookmarks.findIndex(
            b => b.chapterId === bookmark.chapterId && b.pageNumber === bookmark.pageNumber
        );

        if (existingIndex === -1) {
            bookmarks.unshift(newBookmark);
            setStorageItem(STORAGE_KEYS.BOOKMARKS, bookmarks);
        }
    },

    removeBookmark: (chapterId: string, pageNumber: number) => {
        const bookmarks = getStorageItem<Bookmark>(STORAGE_KEYS.BOOKMARKS);
        const filtered = bookmarks.filter(
            b => !(b.chapterId === chapterId && b.pageNumber === pageNumber)
        );
        setStorageItem(STORAGE_KEYS.BOOKMARKS, filtered);
    },

    getChapterBookmarks: (chapterId: string): Bookmark[] => {
        const bookmarks = getStorageItem<Bookmark>(STORAGE_KEYS.BOOKMARKS);
        return bookmarks.filter(b => b.chapterId === chapterId);
    },

    getAllBookmarks: (): Bookmark[] => {
        return getStorageItem<Bookmark>(STORAGE_KEYS.BOOKMARKS);
    },

    clearBookmarks: (): void => {
        try {
            localStorage.removeItem(STORAGE_KEYS.BOOKMARKS);
        } catch (error) {
            console.error('Error clearing bookmarks:', error);
        }
    },

    // Recently Viewed
    addRecentlyViewed: (manga: Manga) => {
        const recentlyViewed = getStorageItem<Manga>(STORAGE_KEYS.RECENTLY_VIEWED);
        
        // Remove if already exists
        const filtered = recentlyViewed.filter(m => m.id !== manga.id);
        
        // Add to front of array
        filtered.unshift(manga);
        
        // Keep only the most recent items
        const trimmed = filtered.slice(0, MAX_RECENTLY_VIEWED);
        
        setStorageItem(STORAGE_KEYS.RECENTLY_VIEWED, trimmed);
    },

    getRecentlyViewed: (): Manga[] => {
        return getStorageItem<Manga>(STORAGE_KEYS.RECENTLY_VIEWED);
    },

    // Clear Data
    clearAllData: () => {
        localStorage.removeItem(STORAGE_KEYS.READING_PROGRESS);
        localStorage.removeItem(STORAGE_KEYS.BOOKMARKS);
        localStorage.removeItem(STORAGE_KEYS.RECENTLY_VIEWED);
    }
};
