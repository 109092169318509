import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initPolyfills } from './polyfills';

// Initialize polyfills
initPolyfills();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// Initialize web vitals with error handling
reportWebVitals(metric => {
  try {
    // You can send the metric to your analytics service here
    console.log(metric);
  } catch (error) {
    console.warn('Failed to report web vital:', error);
  }
});
