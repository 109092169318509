import { ReportHandler } from 'web-vitals';

const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    try {
      const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals');
      
      // Wrap each metric in a try-catch to prevent one failure from affecting others
      const safeMetric = (fn: Function) => {
        try {
          fn(onPerfEntry);
        } catch (error) {
          console.warn('Error reporting web vital:', error);
        }
      };

      safeMetric(getCLS);
      safeMetric(getFID);
      safeMetric(getFCP);
      safeMetric(getLCP);
      safeMetric(getTTFB);
    } catch (error) {
      console.warn('Failed to load web-vitals:', error);
    }
  }
};

export default reportWebVitals;
