import React from 'react';
import {
    Box,
    Image,
    Text,
    VStack,
    Badge,
    useColorModeValue,
    Skeleton,
    AspectRatio,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Manga } from '../types/manga';

interface MangaCardProps {
    manga: Manga;
    isLoading?: boolean;
}

const MangaCard: React.FC<MangaCardProps> = ({ manga, isLoading = false }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    if (isLoading) {
        return (
            <Box
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                bg={bgColor}
                borderColor={borderColor}
                transition="all 0.2s"
                _hover={{ transform: 'scale(1.02)' }}
            >
                <AspectRatio ratio={2/3}>
                    <Skeleton />
                </AspectRatio>
                <VStack p={3} align="start" spacing={1}>
                    <Skeleton height="20px" width="100%" />
                    <Skeleton height="16px" width="60%" />
                </VStack>
            </Box>
        );
    }

    return (
        <Box
            as={RouterLink}
            to={`/manga/${manga.id}`}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            bg={bgColor}
            borderColor={borderColor}
            transition="all 0.2s"
            _hover={{ transform: 'scale(1.02)' }}
            role="group"
        >
            <AspectRatio ratio={2/3}>
                <Image
                    src={manga.coverImage}
                    alt={manga.title}
                    objectFit="cover"
                    fallback={<Skeleton />}
                />
            </AspectRatio>
            <VStack p={3} align="start" spacing={1}>
                <Text
                    fontWeight="semibold"
                    fontSize="sm"
                    noOfLines={2}
                    _groupHover={{ color: 'blue.500' }}
                >
                    {manga.title}
                </Text>
                <Box>
                    {manga.status && (
                        <Badge
                            colorScheme={
                                manga.status === 'ongoing' ? 'green' :
                                manga.status === 'completed' ? 'blue' :
                                'gray'
                            }
                            fontSize="xs"
                        >
                            {manga.status}
                        </Badge>
                    )}
                    {manga.year && (
                        <Badge ml={1} colorScheme="gray" fontSize="xs">
                            {manga.year}
                        </Badge>
                    )}
                </Box>
                <Box>
                    {manga.tags.slice(0, 2).map((tag, index) => (
                        <Badge
                            key={index}
                            colorScheme="blue"
                            variant="outline"
                            fontSize="xs"
                            mr={1}
                        >
                            {tag}
                        </Badge>
                    ))}
                </Box>
            </VStack>
        </Box>
    );
};

export default MangaCard;
