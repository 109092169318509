import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    VStack,
    Box,
    Text,
    Select,
    SimpleGrid,
    Button,
} from '@chakra-ui/react';

interface FilterDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    genres: string[];
    selectedGenres: string[];
    setSelectedGenres: (genres: string[]) => void;
    sortBy: string;
    setSortBy: (sort: string) => void;
}

const SORT_OPTIONS = [
    { value: 'latest', label: 'Latest Updates' },
    { value: 'popular', label: 'Most Popular' },
    { value: 'title', label: 'Title (A-Z)' }
];

const FilterDrawer: React.FC<FilterDrawerProps> = ({
    isOpen,
    onClose,
    genres,
    selectedGenres,
    setSelectedGenres,
    sortBy,
    setSortBy,
}) => {
    const handleGenreSelect = (genre: string) => {
        if (selectedGenres.includes(genre)) {
            setSelectedGenres(selectedGenres.filter(g => g !== genre));
        } else {
            setSelectedGenres([...selectedGenres, genre]);
        }
    };

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Filter Options</DrawerHeader>
                <DrawerBody>
                    <VStack spacing={6} align="stretch">
                        <Box>
                            <Text mb={2} fontWeight="bold">Sort By</Text>
                            <Select
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                            >
                                {SORT_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        </Box>

                        <Box>
                            <Text mb={2} fontWeight="bold">Genres</Text>
                            <SimpleGrid columns={2} spacing={2}>
                                {genres.map(genre => (
                                    <Button
                                        key={genre}
                                        size="sm"
                                        variant={selectedGenres.includes(genre) ? "solid" : "outline"}
                                        colorScheme="blue"
                                        onClick={() => handleGenreSelect(genre)}
                                    >
                                        {genre}
                                    </Button>
                                ))}
                            </SimpleGrid>
                        </Box>
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default FilterDrawer;
