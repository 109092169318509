// Polyfill for IntersectionObserver and related functionality
export function initPolyfills() {
    // Ensure metrics object exists
    if (typeof window !== 'undefined') {
        (window as any).metrics = (window as any).metrics || {};
    }

    // Polyfill IntersectionObserver if needed
    if (typeof IntersectionObserver === 'undefined') {
        console.warn('IntersectionObserver not available, some features may not work properly');
    }

    // Add fallback for page readability metrics
    if (typeof window !== 'undefined' && !(window as any).getIsPageReadable) {
        (window as any).getIsPageReadable = () => true;
    }
}
