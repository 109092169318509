import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Center,
    HStack,
    IconButton,
    Text,
    useColorModeValue,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Textarea,
    ModalFooter,
    useDisclosure,
    useToast,
    Image,
    Tooltip,
} from '@chakra-ui/react';
import { ArrowBackIcon, StarIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { mangaApi } from '../services/mangaApi';
import { userDataService } from '../services/userDataService';
import { Manga, Chapter } from '../types/manga';
import SEO from '../components/SEO';
import MangaStructuredData from '../components/StructuredData';

export default function ChapterReaderPage() {
    const { mangaId = '', chapterId = '' } = useParams<{ mangaId: string; chapterId: string }>();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [pages, setPages] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [manga, setManga] = useState<Manga | null>(null);
    const [currentChapter, setCurrentChapter] = useState<Chapter | null>(null);
    const [allChapters, setAllChapters] = useState<Chapter[]>([]);
    const [bookmarkNote, setBookmarkNote] = useState('');
    const [bookmarks, setBookmarks] = useState<any[]>([]);

    const containerRef = useRef<HTMLDivElement>(null);
    const bgColor = useColorModeValue('white', 'gray.800');

    // Track scroll position to update current page
    const handleScroll = () => {
        try {
            if (!containerRef.current) return;
            
            const container = containerRef.current;
            const images = container.getElementsByTagName('img');
            const containerTop = container.getBoundingClientRect().top;
            
            let currentVisiblePage = 0;
            for (let i = 0; i < images.length; i++) {
                const imageRect = images[i].getBoundingClientRect();
                if (!imageRect) continue; // Skip if getBoundingClientRect fails
                const imageCenter = imageRect.top + imageRect.height / 2;
                
                if (imageCenter > containerTop) {
                    currentVisiblePage = i;
                    break;
                }
            }
            
            setCurrentPage(currentVisiblePage);
            
            // Update reading progress
            if (manga && currentChapter) {
                userDataService.saveReadingProgress({
                    mangaId,
                    chapterId: currentChapter.id,
                    chapterNumber: currentChapter.chapter,
                    lastReadPage: currentVisiblePage,
                    mangaTitle: manga.title,
                    mangaCover: manga.coverImage
                });
            }
        } catch (error) {
            console.warn('Error in scroll handler:', error);
        }
    };

    useEffect(() => {
        const loadChapter = async () => {
            try {
                setIsLoading(true);
                setError(null);

                // Load manga details if not already loaded
                if (!manga) {
                    const mangaDetails = await mangaApi.getMangaDetails(mangaId);
                    setManga(mangaDetails);
                }

                // Load all chapters
                const chaptersData = await mangaApi.getMangaChapters(mangaId);
                setAllChapters(chaptersData.sort((a, b) => 
                    parseFloat(a.chapter) - parseFloat(b.chapter)
                ));

                // Load chapter details
                const chapter = await mangaApi.getMangaChapter(chapterId);
                setCurrentChapter(chapter);

                // Load chapter pages
                const pageUrls = await mangaApi.getChapterPages(chapterId);
                setPages(pageUrls);
                chapter.pages = pageUrls; // Update chapter with actual pages

                setIsLoading(false);
            } catch (error) {
                console.error('Error loading chapter:', error);
                setError('Failed to load chapter. Please try again.');
                setIsLoading(false);
            }
        };

        loadChapter();
    }, [mangaId, chapterId]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [manga, currentChapter]);

    useEffect(() => {
        if (!manga || !currentChapter) return;
        document.title = `Ch. ${currentChapter.chapter} - ${manga.title}`;
    }, [manga, currentChapter]);

    const navigateToChapter = (chapter: Chapter) => {
        navigate(`/manga/${mangaId}/chapter/${chapter.id}`);
    };

    const getAdjacentChapters = () => {
        if (!currentChapter || !allChapters.length) return { prev: null, next: null };
        
        const currentIndex = allChapters.findIndex(ch => ch.id === currentChapter.id);
        return {
            prev: currentIndex > 0 ? allChapters[currentIndex - 1] : null,
            next: currentIndex < allChapters.length - 1 ? allChapters[currentIndex + 1] : null
        };
    };

    const { prev, next } = getAdjacentChapters();

    const handleAddBookmark = () => {
        if (!manga || !currentChapter) return;

        userDataService.addBookmark({
            mangaId,
            chapterId: currentChapter.id,
            chapterNumber: currentChapter.chapter,
            pageNumber: currentPage,
            mangaTitle: manga.title,
            mangaCover: manga.coverImage,
            note: bookmarkNote
        });

        const updatedBookmarks = userDataService.getChapterBookmarks(currentChapter.id);
        setBookmarks(updatedBookmarks);

        toast({
            title: 'Bookmark added',
            status: 'success',
            duration: 2000,
        });

        setBookmarkNote('');
        onClose();
    };

    const handleRemoveBookmark = () => {
        if (!currentChapter) return;

        userDataService.removeBookmark(currentChapter.id, currentPage);
        const updatedBookmarks = userDataService.getChapterBookmarks(currentChapter.id);
        setBookmarks(updatedBookmarks);

        toast({
            title: 'Bookmark removed',
            status: 'info',
            duration: 2000,
        });
    };

    return (
        <Box minH="100vh" bg={bgColor}>
            {manga && currentChapter && (
                <>
                    <SEO
                        title={`${currentChapter.title || `Chapter ${currentChapter.chapter}`} - ${manga.title} | 1331 Manga`}
                        description={`Read ${manga.title} Chapter ${currentChapter.chapter}${currentChapter.title ? ` - ${currentChapter.title}` : ''} online for free on 1331 Manga`}
                        image={currentChapter.pages?.[0] || manga.coverImage}
                    />
                    <MangaStructuredData
                        title={`${manga.title} - Chapter ${currentChapter.chapter}`}
                        description={`Read ${manga.title} Chapter ${currentChapter.chapter}${currentChapter.title ? ` - ${currentChapter.title}` : ''} online for free on 1331 Manga`}
                        image={currentChapter.pages?.[0] || manga.coverImage}
                        author={manga.author}
                        genre={manga.genres}
                    />
                </>
            )}
            {/* Navigation Bar */}
            <HStack 
                spacing={2} 
                p={2} 
                bg={bgColor} 
                position="fixed" 
                top={0} 
                left={0} 
                right={0} 
                zIndex={10}
                boxShadow="sm"
            >
                <IconButton
                    aria-label="Back to manga"
                    icon={<ArrowBackIcon />}
                    onClick={() => navigate(`/manga/${mangaId}`)}
                />
                
                <Text flex={1} isTruncated>
                    {manga?.title} - Chapter {currentChapter?.chapter}
                </Text>

                <Tooltip label={bookmarks.length > 0 ? 'View Bookmarks' : 'Add Bookmark'}>
                    <IconButton
                        aria-label="Bookmark"
                        icon={<StarIcon />}
                        onClick={onOpen}
                        colorScheme={bookmarks.length > 0 ? 'yellow' : 'gray'}
                    />
                </Tooltip>

                {/* Chapter Navigation */}
                <HStack>
                    <Tooltip label="Previous Chapter">
                        <IconButton
                            aria-label="Previous chapter"
                            icon={<ChevronLeftIcon />}
                            onClick={() => prev && navigateToChapter(prev)}
                            isDisabled={!prev}
                        />
                    </Tooltip>
                    <Tooltip label="Next Chapter">
                        <IconButton
                            aria-label="Next chapter"
                            icon={<ChevronRightIcon />}
                            onClick={() => next && navigateToChapter(next)}
                            isDisabled={!next}
                        />
                    </Tooltip>
                </HStack>
            </HStack>

            {/* Main Content */}
            <Box
                ref={containerRef}
                pt="60px"
                height="100%"
                overflow="auto"
                onScroll={handleScroll}
            >
                {isLoading ? (
                    <Center height="calc(100vh - 60px)">
                        <Text>Loading chapter...</Text>
                    </Center>
                ) : error ? (
                    <Center height="calc(100vh - 60px)">
                        <VStack spacing={4}>
                            <Text color="red.500">{error}</Text>
                            <Button onClick={() => window.location.reload()}>
                                Retry
                            </Button>
                        </VStack>
                    </Center>
                ) : (
                    <VStack spacing={4} pb={4}>
                        {pages.map((pageUrl, index) => (
                            <Image
                                key={`${pageUrl}-${index}`}
                                id={`page-${index}`}
                                src={pageUrl}
                                alt={`Page ${index + 1}`}
                                width="100%"
                                maxW="800px"
                                loading="lazy"
                                onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = 'https://via.placeholder.com/800x1200?text=Failed+to+load+image';
                                }}
                            />
                        ))}
                    </VStack>
                )}
            </Box>

            {/* Bookmark Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Bookmark</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Text>Page {currentPage + 1}</Text>
                            <Textarea
                                placeholder="Add a note (optional)"
                                value={bookmarkNote}
                                onChange={(e) => setBookmarkNote(e.target.value)}
                            />
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleAddBookmark}>
                            Save Bookmark
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}
