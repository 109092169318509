import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Manga } from '../types/manga';

interface MangaState {
    mangas: Manga[];
    currentManga: Manga | null;
    loading: boolean;
    error: string | null;
}

const initialState: MangaState = {
    mangas: [],
    currentManga: null,
    loading: false,
    error: null,
};

// TODO: Replace with actual API calls
export const fetchMangas = createAsyncThunk(
    'manga/fetchMangas',
    async () => {
        // Simulate API call
        return [] as Manga[];
    }
);

export const fetchMangaById = createAsyncThunk(
    'manga/fetchMangaById',
    async (id: string) => {
        // Simulate API call
        return null as unknown as Manga;
    }
);

const mangaSlice = createSlice({
    name: 'manga',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMangas.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMangas.fulfilled, (state, action) => {
                state.loading = false;
                state.mangas = action.payload;
            })
            .addCase(fetchMangas.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch mangas';
            })
            .addCase(fetchMangaById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMangaById.fulfilled, (state, action) => {
                state.loading = false;
                state.currentManga = action.payload;
            })
            .addCase(fetchMangaById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch manga';
            });
    },
});

export default mangaSlice.reducer;
