import React from 'react';
import {
    Box,
    Container,
    Flex,
    Button,
    HStack,
    useColorMode,
    useColorModeValue,
    IconButton,
    Heading,
    Show,
    Hide,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useBreakpointValue
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { MoonIcon, SunIcon, InfoIcon, ExternalLinkIcon, HamburgerIcon } from '@chakra-ui/icons';
import { FaDiscord } from 'react-icons/fa';

export const Navbar: React.FC = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Box 
            bg={bgColor} 
            px={[2, 4]} 
            position="fixed" 
            top={0} 
            zIndex={100}
            borderBottom="1px"
            borderColor={borderColor}
            width="100%"
        >
            <Container maxW="container.xl">
                <Flex h={16} alignItems="center" justifyContent="space-between">
                    <HStack spacing={[2, 8]}>
                        <RouterLink to="/">
                            <Heading size={["sm", "md"]} _hover={{ color: 'blue.500' }}>
                                1331 Manga
                            </Heading>
                        </RouterLink>
                        
                        <Show above="md">
                            <HStack spacing={4}>
                                <Button
                                    as={RouterLink}
                                    to="/library"
                                    variant="ghost"
                                    leftIcon={<InfoIcon />}
                                    size={["sm", "md"]}
                                >
                                    Library
                                </Button>
                                <Button
                                    as={RouterLink}
                                    to="/"
                                    variant="ghost"
                                    size={["sm", "md"]}
                                >
                                    Browse
                                </Button>
                                <Button
                                    as="a"
                                    href="https://discord.gg/ztZADdEZzP"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant="solid"
                                    colorScheme="purple"
                                    leftIcon={<FaDiscord />}
                                    size={["sm", "md"]}
                                >
                                    Join Discord
                                </Button>
                            </HStack>
                        </Show>
                    </HStack>

                    <HStack spacing={2}>
                        <IconButton
                            aria-label="Toggle color mode"
                            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                            onClick={toggleColorMode}
                            variant="ghost"
                            size={["sm", "md"]}
                        />
                        
                        <Hide above="md">
                            <Menu>
                                <MenuButton
                                    as={IconButton}
                                    aria-label="Menu"
                                    icon={<HamburgerIcon />}
                                    variant="ghost"
                                    size="sm"
                                />
                                <MenuList>
                                    <MenuItem as={RouterLink} to="/library">
                                        Library
                                    </MenuItem>
                                    <MenuItem as={RouterLink} to="/">
                                        Browse
                                    </MenuItem>
                                    <MenuItem 
                                        as="a"
                                        href="https://discord.gg/ztZADdEZzP"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Join Discord
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Hide>
                    </HStack>
                </Flex>
            </Container>
        </Box>
    );
};

export default Navbar;
