import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MangaStructuredData {
  title: string;
  description: string;
  image?: string;
  author?: string;
  datePublished?: string;
  genre?: string[];
}

export const MangaStructuredData: React.FC<MangaStructuredData> = ({
  title,
  description,
  image,
  author,
  datePublished,
  genre
}) => {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Book',
    '@id': window.location.href,
    name: title,
    description: description,
    image: image,
    author: author ? {
      '@type': 'Person',
      name: author
    } : undefined,
    datePublished,
    genre,
    bookFormat: 'GraphicNovel',
    potentialAction: {
      '@type': 'ReadAction',
      target: window.location.href
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default MangaStructuredData;
