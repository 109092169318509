import React from 'react';
import { Box } from '@chakra-ui/react';
import Navbar from './Navbar';

interface LayoutProps {
    children: React.ReactNode;
    showNavbar?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, showNavbar = true }) => {
    return (
        <Box>
            {showNavbar && <Navbar />}
            <Box pt="var(--chakra-space-14)" mt={0}>
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
