import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    SimpleGrid,
    VStack,
    Text,
    Button,
    HStack,
    Image,
    useColorModeValue,
    IconButton,
    useToast,
} from '@chakra-ui/react';
import { DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { userDataService } from '../services/userDataService';

const LibraryPage: React.FC = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    const [history, setHistory] = useState<any[]>([]);
    const [bookmarks, setBookmarks] = useState<any[]>([]);

    useEffect(() => {
        // Load reading history and bookmarks
        setHistory(userDataService.getReadingHistory());
        setBookmarks(userDataService.getAllBookmarks());
    }, []);

    const handleClearHistory = () => {
        userDataService.clearReadingHistory();
        setHistory([]);
        toast({
            title: 'Reading history cleared',
            status: 'info',
            duration: 2000,
        });
    };

    const handleClearBookmarks = () => {
        userDataService.clearBookmarks();
        setBookmarks([]);
        toast({
            title: 'Bookmarks cleared',
            status: 'info',
            duration: 2000,
        });
    };

    const handleRemoveBookmark = (chapterId: string, pageNumber: number) => {
        userDataService.removeBookmark(chapterId, pageNumber);
        setBookmarks(userDataService.getAllBookmarks());
        toast({
            title: 'Bookmark removed',
            status: 'info',
            duration: 2000,
        });
    };

    const formatDate = (timestamp: number) => {
        return new Date(timestamp).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    return (
        <Container maxW="container.xl" py={8}>
            <Heading mb={8}>My Library</Heading>

            <Tabs variant="enclosed">
                <TabList>
                    <Tab>Reading History ({history.length})</Tab>
                    <Tab>Bookmarks ({bookmarks.length})</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <HStack justify="space-between" mb={4}>
                            <Heading size="md">Recent Reads</Heading>
                            <Button
                                size="sm"
                                colorScheme="red"
                                variant="ghost"
                                leftIcon={<DeleteIcon />}
                                onClick={handleClearHistory}
                                isDisabled={history.length === 0}
                            >
                                Clear History
                            </Button>
                        </HStack>

                        {history.length === 0 ? (
                            <Text color="gray.500">No reading history yet</Text>
                        ) : (
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                {history.map((item) => (
                                    <Box
                                        key={`${item.mangaId}-${item.chapterId}`}
                                        p={4}
                                        borderWidth="1px"
                                        borderRadius="lg"
                                        bg={bgColor}
                                        borderColor={borderColor}
                                    >
                                        <HStack spacing={4}>
                                            <Image
                                                src={item.mangaCover}
                                                alt={item.mangaTitle}
                                                boxSize="100px"
                                                objectFit="cover"
                                                borderRadius="md"
                                            />
                                            <VStack align="start" flex={1}>
                                                <Text fontWeight="bold" noOfLines={1}>
                                                    {item.mangaTitle}
                                                </Text>
                                                <Text fontSize="sm" color="gray.500">
                                                    Chapter {item.chapterNumber}
                                                </Text>
                                                <Text fontSize="sm" color="gray.500">
                                                    Page {item.lastReadPage + 1}
                                                </Text>
                                                <Text fontSize="xs" color="gray.500">
                                                    {formatDate(item.timestamp)}
                                                </Text>
                                            </VStack>
                                            <IconButton
                                                aria-label="Continue reading"
                                                icon={<ExternalLinkIcon />}
                                                onClick={() => navigate(`/manga/${item.mangaId}/chapter/${item.chapterId}`)}
                                                size="sm"
                                            />
                                        </HStack>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        )}
                    </TabPanel>

                    <TabPanel>
                        <HStack justify="space-between" mb={4}>
                            <Heading size="md">Bookmarks</Heading>
                            <Button
                                size="sm"
                                colorScheme="red"
                                variant="ghost"
                                leftIcon={<DeleteIcon />}
                                onClick={handleClearBookmarks}
                                isDisabled={bookmarks.length === 0}
                            >
                                Clear All
                            </Button>
                        </HStack>

                        {bookmarks.length === 0 ? (
                            <Text color="gray.500">No bookmarks yet</Text>
                        ) : (
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                {bookmarks.map((bookmark) => (
                                    <Box
                                        key={`${bookmark.mangaId}-${bookmark.chapterId}-${bookmark.pageNumber}`}
                                        p={4}
                                        borderWidth="1px"
                                        borderRadius="lg"
                                        bg={bgColor}
                                        borderColor={borderColor}
                                    >
                                        <HStack spacing={4}>
                                            <Image
                                                src={bookmark.mangaCover}
                                                alt={bookmark.mangaTitle}
                                                boxSize="100px"
                                                objectFit="cover"
                                                borderRadius="md"
                                            />
                                            <VStack align="start" flex={1}>
                                                <Text fontWeight="bold" noOfLines={1}>
                                                    {bookmark.mangaTitle}
                                                </Text>
                                                <Text fontSize="sm">
                                                    Chapter {bookmark.chapterNumber} - Page {bookmark.pageNumber + 1}
                                                </Text>
                                                {bookmark.note && (
                                                    <Text fontSize="sm" color="gray.500" noOfLines={2}>
                                                        {bookmark.note}
                                                    </Text>
                                                )}
                                                <Text fontSize="xs" color="gray.500">
                                                    {formatDate(bookmark.timestamp)}
                                                </Text>
                                            </VStack>
                                            <VStack>
                                                <IconButton
                                                    aria-label="Go to bookmark"
                                                    icon={<ExternalLinkIcon />}
                                                    onClick={() => navigate(`/manga/${bookmark.mangaId}/chapter/${bookmark.chapterId}`)}
                                                    size="sm"
                                                />
                                                <IconButton
                                                    aria-label="Remove bookmark"
                                                    icon={<DeleteIcon />}
                                                    onClick={() => handleRemoveBookmark(bookmark.chapterId, bookmark.pageNumber)}
                                                    size="sm"
                                                    colorScheme="red"
                                                    variant="ghost"
                                                />
                                            </VStack>
                                        </HStack>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    );
};

export default LibraryPage;
