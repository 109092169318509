import React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import HomePage from './pages/HomePage';
import MangaDetailPage from './pages/MangaDetailPage';
import ChapterReaderPage from './pages/ChapterReaderPage';
import Layout from './components/Layout';
import { Provider } from 'react-redux';
import { store } from './store/store';
import theme from './theme';
import LibraryPage from './pages/LibraryPage';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <Router>
            <Routes>
              <Route path="/" element={
                <Layout>
                  <HomePage />
                </Layout>
              } />
              <Route path="/library" element={
                <Layout>
                  <LibraryPage />
                </Layout>
              } />
              <Route path="/manga/:mangaId" element={
                <Layout>
                  <MangaDetailPage />
                </Layout>
              } />
              <Route path="/manga/:mangaId/chapter/:chapterId" element={
                <Layout showNavbar={false}>
                  <ChapterReaderPage />
                </Layout>
              } />
            </Routes>
          </Router>
        </ChakraProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
